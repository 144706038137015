(function($) {
  'use strict';
  $(function(){
    $('.accordion-header').toggleClass('inactive-header');
      // The Accordion Effect
      $('.accordion-header').click(function (e) {
        e.preventDefault();
        if($(this).is('.inactive-header')) {
          $('.active-header').toggleClass('active-header').toggleClass('inactive-header').next().slideToggle().toggleClass('open-content');
          $(this).toggleClass('active-header').toggleClass('inactive-header');
          $(this).next().slideToggle().toggleClass('open-content');
        }
        else {
          $(this).toggleClass('active-header').toggleClass('inactive-header');
          $(this).next().slideToggle().toggleClass('open-content');
        }
      });
  });
})(jQuery);



// (function($) {
//   'use strict';
//   $(function(){
//     $('.accordion-header').toggleClass('inactive-header');
//       // The Accordion Effect
//       $('.accordion-header').click(function (e) {
//         e.preventDefault();
//         if($(this).is('.inactive-header')) {
//           // Hide the active tab
//           $('.active-header').removeClass('active-header').addClass('inactive-header').next().velocity('slideUp').removeClass('open-content');

//           // Show this tab
//           $(this).addClass('active-header').removeClass('inactive-header');
//           $(this).next().velocity('slideDown').addClass('open-content');
//         }
//         else {
//           // Hide this tab
//           $(this).removeClass('active-header').addClass('inactive-header').next().velocity('slideUp').removeClass('open-content');
//         }
//       });
//   });
// })(jQuery);