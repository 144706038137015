/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var BrowserDetect = {
      init: function () {
          this.browser = this.searchString(this.dataBrowser) || "Other";
          this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
      },
      searchString: function (data) {
          for (var i = 0; i < data.length; i++) {
              var dataString = data[i].string;
              this.versionSearchString = data[i].subString;

              if (dataString.indexOf(data[i].subString) !== -1) {
                  return data[i].identity;
              }
          }
      },
      searchVersion: function (dataString) {
          var index = dataString.indexOf(this.versionSearchString);
          if (index === -1) {
              return;
          }

          var rv = dataString.indexOf("rv:");
          if (this.versionSearchString === "Trident" && rv !== -1) {
              return parseFloat(dataString.substring(rv + 3));
          } else {
              return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
          }
      },

      dataBrowser: [
          {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
          {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
          {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
          {string: navigator.userAgent, subString: "Safari", identity: "Safari"},
          {string: navigator.userAgent, subString: "Opera", identity: "Opera"}
      ]

  };

BrowserDetect.init();

if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function() {
        FastClick.attach(document.body);
    }, false);
}
Modernizr.addTest('svgasimg', document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'));
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(window).load(function(){
          // if(!(BrowserDetect.browser === "Explorer" && BrowserDetect.version === 8)){
          //   viewportUnitsBuggyfill.init({refreshDebounceWait: 250});
          // }
        });
        if(!Modernizr.svgasimg){
          $('img').each(function() {
            var img_src = $(this).attr('src');
            var new_src = img_src.replace(/\.svg$/, '.png');
            $(this).attr('src', new_src);
          });
        }
        if(!Modernizr.touch){
          $("a[href*='tel:']").on('click',function(){
            return false;
          });
        }
        if(Modernizr.input.placeholder){
          //$("label").hide();
        }
        $("a.appointment").on('click', function() {
          $("html").addClass('lock');
          $("#loader-2").addClass('show');
          return false;
        });
        $("#loader-2 .close").on('click',function(){
          $("#loader-2").removeClass('show');
          $("html").removeClass('lock');
        });
        $('html').click(function(e) {
          if($("#loader-2").hasClass('show')){
            if($(e.target).closest('a.appointment').length === 0 && $(e.target).closest('#loader-2').length === 0  && !$(e.target).is("#loader-2") && $(e.target).closest('.ui-datepicker').length === 0 && $(e.target).attr('class').indexOf('ui-') !== 0){
              $("#loader-2").removeClass('show');
              $("html").removeClass('lock');
            }
          }
        });
        $(".open-loader").on('click', function() {
          var $innerContent = $($(this).data('content')).html();
          $("#loader .content").html($innerContent);
          $("html").addClass('lock');
          $("#loader").addClass('show');
          return false;
        });
        $("#loader .close").on('click',function(){
          $("#loader").removeClass('show');
          $("html").removeClass('lock');
        });
        $('html').click(function(e) {
          if($("#loader").hasClass('show')){
            if($(e.target).closest('span.team-member').length === 0 && $(e.target).closest('#loader').length === 0  && !$(e.target).is("#loader")){
              $("#loader").removeClass('show');
              $("html").removeClass('lock');
            }
          }
        });
        $("a.video").magnificPopup({
          type:'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 300,
           callbacks:{
            open:function(){
              var pInstance = $(".hero.front").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.pause();
              }
            },
            close:function(){
              var pInstance = $(".hero.front").data('vide');
              if(pInstance){
                pInstance = pInstance.getVideoObject();
                pInstance.play();
              }
            }
          },
          iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                      '<div class="mfp-close"></div>'+
                      '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

            patterns: {
              youtube: {
                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                // Or null - full URL will be returned
                // Or a function that should return %id%, for example:
                // id: function(url) { return 'parsed id'; }

                src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
              },
              vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1'
              },
              gmaps: {
                index: '//maps.google.',
                src: '%id%&output=embed'
              }
            }
          }
        });
        $('a.gallery-item').magnificPopup({
          type: 'image',
          mainClass: 'mfp-fade',
          removalDelay: 300,
          gallery:{
            enabled:true
          },
          image: {
            markup: '<div class="mfp-figure">'+
                      '<div class="mfp-close"></div>'+
                      '<div class="mfp-img"></div>'+
                      '<div class="mfp-bottom-bar">'+
                        '<div class="mfp-title"></div>'+
                        '<div class="mfp-counter"></div>'+
                      '</div>'+
                    '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

            //titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
            // Or the function that should return the title. For example:
            titleSrc: function(item) {
              //console.log($(item.el).find('img').attr('title'));
              return $(item.el).find('img').attr('title');
            },

            verticalFit: true, // Fits image in area vertically

            tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
          }
        });
      },
      finalize: function() {
        // Skin Conditions slider
        $('.section-skin-conditions .conditions').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          mobileFirst: true,
          arrows: true,
          speed: 400,
          easing: 'easeInOutQuad',
          // autoplay: true,
          autoplaySpeed: 3000,
          prevArrow: '<a class="slick-prev"><span class="icon-left"></span></a>',
          nextArrow: '<a class="slick-next"><span class="icon-right"></span></a>',
          responsive: [
            {
              breakpoint: 550,
              settings: {
                dots:false,
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 1025,
              settings: {
                dots:true,
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 1440,
              settings: {
                dots:true,
                slidesToShow: 4,
                slidesToScroll: 4
              }
            },
            {
              breakpoint: 1750,
              settings: {
                dots:true,
                slidesToShow: 5,
                slidesToScroll: 5
              }
            }
          ]
        });

        $(".matchHeight").matchHeight();
        $("[data-block]").on('click',function(){
          var $el = $($(this).data('block'));
          $("html").velocity("scroll", { offset: $el.offset().top, mobileHA: false });
          return false;
        });

        //
        $('.hero .wrap-inner').velocity('transition.slideUpIn');
        $('.hero .bottom').velocity('transition.slideDownIn');

        // Waypoints
        $('.hero').waypoint({
          handler: function(direction) {
            // console.log(direction);
            $('body').toggleClass('header-stuck');
          },
          offset: -$('.hero').outerHeight(),
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        if(!Modernizr.touch){
          var player = $(".hero.front").vide('https://player.vimeo.com/external/167162926.hd.mp4?s=47346d3b7e476c93dbdf3690b76b96d44a0a9158&profile_id=174',{
            className: 'videojs-background-wrap overlay',
            posterType: 'none',
            bgColor: '#3589a1'
          });
        }
      },
      finalize: function() {
        // Match some heights
        $('.featured-posts .content-inner').matchHeight();
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_id_458': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(".team-member").hoverIntent({
           over: function(){
             $(this).addClass('over');
             $(this).removeClass('out');
           },
           out:function(){
             $(this).removeClass('over');
             $(this).addClass('out');
           },
           timeout:250
         });
      }
    },
    'our_team': {
      init: function() {

      },
      finalize: function() {
        $('.team-members-wrap .team-member .team-info').matchHeight();
      }
    },
    'contact': {
      init: function() {

      },
       finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
