function gmapInit() {
  if($("#map-canvas").length === 0){
    return false;
  }
  var image = '/wp-content/themes/lafrance/dist/images/marker.png';
  var lat = 48.424468 ;
  var lng = -123.364606;
  if($("#map-canvas").data('lat') !== undefined && $("#map-canvas").data('lng') !== undefined){
    lat = $("#map-canvas").data('lat');
    lng = $("#map-canvas").data('lng');
  }
  var myLatlng = new google.maps.LatLng(lat, lng);
  var mapOptions = {
    zoom: 15,
    center: myLatlng,
    panControl: false,
    zoomControl: true,
    scaleControl: false,
    scrollwheel: false,
    //styles:[{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"visibility":"simplified"},{"hue":"#0066ff"},{"saturation":74},{"lightness":100}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"off"},{"weight":0.6},{"saturation":-85},{"lightness":61}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"simplified"},{"color":"#5f94ff"},{"lightness":26},{"gamma":5.86}]}]
  };
  if(Modernizr.touch){
    mapOptions.draggable = false;
  }
  var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

  var marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: image
  });
  var infowindow = new google.maps.InfoWindow({
    content   : '<div class="info-window"><ul>'+$(".contact-col ul.address").html()+'</ul></div>'
  });
  // show info window when marker is clicked
  google.maps.event.addListener(marker, 'click', function() {
    infowindow.open( map, marker );
  });
  var currCenter = map.getCenter();
  google.maps.event.addDomListener(window, 'resize', function() {
    map.setCenter(currCenter);
  });
  google.maps.event.addDomListener(window, 'orientationchange', function() {
    map.setCenter(currCenter);
  });
}
