function hideSearch() {
  $("form.searchform").velocity({
    opacity: 0,
    scale: [0.8, 1],
  }, 300, 'easeInOutCubic', function() {
    $(".search-feature").velocity('fadeOut', 400, 'easeInCubic').removeClass('visible');
  });
}

function showSearch() {
  $(".search-feature").velocity('fadeIn', 400, 'easeInCubic', function() {
    $("form.searchform").velocity({
      opacity: 1,
      scale: [1, 0.8],
    }, 300, 'easeInOutCubic', function() {
      $("input.search").focus();
    });

    $(this).addClass('visible');
  });
}

$(function(){
  // Show that search overlay
  $('a.search, .menu-search a').click(function(e){
    showSearch();
    e.preventDefault();
  });

  // Hide that search overlay
  $('body').on('click', 'button.close-search, .search-feature.visible .content', function(e){
    hideSearch();
    e.preventDefault();
  });

  // Stahp
  $('.search-feature form').on('click', function(e) {
    e.stopPropagation();
  });

  $(document).keyup(function(e) {
    if (e.keyCode === 27 && $('.search-feature').hasClass('visible')) {
      hideSearch();
    }
  });
});